<template>

    <div style="height:100%; padding: 10px 0px;">

        <button class="popup-button" @click="closePopup" @keydown.prevent.enter="closePopup" @keydown.tab="trapFocus" :aria-label="$t('navigation.closePopup')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30" height="30" overflow="visible" stroke="#fff" stroke-width="5" stroke-linecap="round">
                <line x1="10" y1="10" x2="40" y2="40" />
                <line x1="40" y1="10" x2="10" y2="40" />
            </svg>
        </button>
        <div id ="popupWrapper">

            <div id="popUpContent" @keydown.tab="trapFocus" v-show="showPopupText === 'ads_info'">

                <div class="popUpText">
                    <div class="textWrapper">
                        <h4>{{$t('popUp.title')}}</h4>
                        <p class="col2">{{$t('popUp.text')}}</p>
                        <ul>
                            <li>{{$t('popUp.ul.li1')}}<br/><br/>{{$t('popUp.ul.li2')}}</li>
                            <li>{{$t('popUp.ul.li3')}}</li>
                            <li v-if="$i18n.locale ==='nl_BE' || $i18n.locale ==='nl_NL'">{{$t('popUp.ul.li4')}}<br/><br/>{{$t('popUp.ul.li5')}}<br/><br/><a :href="$t('popUp.ul.li6Link')" target="_blank" :aria-label="$t('popUp.ul.link1AriaLabel')">{{$t('popUp.ul.li6LinkLabel')}}</a>{{$t('popUp.ul.li6')}} <a :href="$t('popUp.ul.li6Link2')" target="_blank" :aria-label="$t('popUp.ul.link1AriaLabel2')">{{$t('popUp.ul.li6LinkLabel2')}}</a></li>
                            <li v-else>{{$t('popUp.ul.li4')}}<br/><br/>{{$t('popUp.ul.li5')}}<br/><br/>{{$t('popUp.ul.li6')}} <a :href="$t('popUp.ul.li6Link')" target="_blank" :aria-label="$t('popUp.ul.link1AriaLabel')">{{$t('popUp.ul.li6LinkLabel')}}</a> {{$t('popUp.ul.li6Part2')}} <a :href="$t('popUp.ul.li6Link2')" target="_blank" class="lastPopupLink" :aria-label="$t('popUp.ul.link1AriaLabel2')">{{$t('popUp.ul.li6LinkLabel2')}}</a></li>
                        </ul>
                    </div>

                </div>
            </div>

            <div id="popUpContent2" @keydown.tab="trapFocus" v-show="showPopupText === 'privacy_info'">

                <div class="popUpText">
                    <div class="textWrapper">
                        <h4>{{$t('popUp2.title')}}</h4>
                        <ul>
                            <li>{{$t('popUp2.ul.li1')}}</li>
                            <li>{{$t('popUp2.ul.li2')}} <a :href="$t('popUp2.ul.li2Link')" target="_blank" :aria-label="$t('popUp2.ul.link2AriaLabel')">{{$t('popUp2.ul.li2LinkLabel')}}</a> {{$t('popUp2.ul.li2Part2')}}</li>
                            <li>{{$t('popUp2.ul.li3')}} <a :href="$t('popUp2.ul.li3Link')" target="_blank" :aria-label="$t('popUp2.ul.link2AriaLabel2')">{{$t('popUp2.ul.li3LinkLabel')}}</a> {{$t('popUp2.ul.li3Part2')}} <a :href="$t('popUp2.ul.li3Link2')" target="_blank" :aria-label="$t('popUp2.ul.link2AriaLabel3')">{{$t('popUp2.ul.li3LinkLabel2')}}</a> {{$t('popUp2.ul.li3Part3')}}</li>
                            <li>{{$t('popUp2.ul.li4')}} <a :href="$t('popUp2.ul.li4Link')" target="_blank" :aria-label="$t('popUp2.ul.link2AriaLabel4')">{{$t('popUp2.ul.li4LinkLabel')}}</a> {{$t('popUp2.ul.li4Part2')}}</li>
                            <li>{{$t('popUp2.ul.li5')}} <a :href="$t('popUp2.ul.li5Link')" target="_blank" class="lastPopupLink" :aria-label="$t('popUp2.ul.link2AriaLabel5')">{{$t('popUp2.ul.li5LinkLabel')}}</a> {{$t('popUp2.ul.li5Part2')}}</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>

<script>

    export default {
        name: "popup",
        mixins: [

            ],

        emits: ["popUpStatus"],

        props:{
            showPopupText:{
                type: Boolean,
            default: false,
            },
        },


        computed: {

        },

        methods: {
            closePopup(event){
                this.$emit('popUpStatus',false);
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                return false;

            },

            trapFocus(event) {

                const modalButton = document.getElementsByClassName("popup-button")[0];
                const modal1 = document.getElementById("popUpContent");
                const modal2 = document.getElementById("popUpContent2");

                // Get all focusable elements inside the modal
                const focusableElements = modal1.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                    );
                const focusableElements2 = modal2.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                    );

                const firstFocusable = modalButton;
                const lastFocusable = focusableElements[focusableElements.length - 1];
                const lastFocusable2 = focusableElements2[focusableElements2.length - 1];

                if (event.shiftKey && document.activeElement === firstFocusable) {
                    // If shift key is pressed and focus is on the first element, move focus to the last element
                    event.preventDefault();
                    lastFocusable.focus();
                    lastFocusable2.focus();

                    if(this.showPopupText === 'ads_info'){
                        lastFocusable.focus();

                    }else if(this.showPopupText === 'privacy_info'){
                        lastFocusable2.focus();
                    }

                } else if (!event.shiftKey && document.activeElement === lastFocusable || !event.shiftKey && document.activeElement === lastFocusable2) {
                    // If shift key is not pressed and focus is on the last element, move focus to the first element
                    event.preventDefault();
                    firstFocusable.focus();
                }
            },
        }


    }
</script>

<style scoped>

    .popup-button{
        font-size:1.5em;
        background-color: #000;
        border: 2px solid #000;
        color: #fff;
        font-weight: bold;
        position: fixed;
        top: -50px;
        right: -25px;
        margin-top: 1em;
        /*padding: 0.5em 0.5em;*/
        //padding: 15px 0px!important;
        cursor: pointer;
        text-align: center;
        z-index: 9999;
        transition:100ms;
        border-radius: 50px;
        width: 60px;
        height: 60px;
        line-height: 70px;
        &:hover {
         background-color: #6f6f6f;
         transition:200ms;
     }
 }
 #popupWrapper{
    overflow-y: auto;
    height: 100%;
}
#popUpContent{
    position:relative;
}

img, p{
    display:inline;
}

.popUpText{
    display:flex;
    align-items: center;
    padding: 20px;

}
@media screen and (max-width: 764px) {
    .popup-button {
        width: 200px;
        right: calc(350px - 100px);
    }
}
@media screen and (max-width: 698px){
    #popUpContent {
        top: 0%;
        margin-top: 0px;
    }

    .popUpText{
        flex-direction: column;
        height:100%;
    }
    .imgWrapper img{
        width:100%
    }
    .popup-button {
        width: 200px;
        right: calc(150px - 100px);
    }
}
.textWrapper{
    padding: 1em;
}
li{
    padding: 1em 0px;
    list-style-type: disc;
    list-style-position: inside;
}

</style>