export default {
	data () {
		return {

			/* LOCALE BUTTONS */
			/* Buttons can display 'langs', 'countries' or 'both'. 'Locale' should be for specific usage, like debugging. */
			modelLangButton: ['countries', 'langs', 'both', 'locale'][0],

			/* LOADER TYPE */
			loaderType: ['default', 'gradient', 'popIn', 'horizontalMove', 'horizontalMoveAlt', 'circlesRotating', 'bouncingDots', 'coffeeLoader', 'doubleSpinner', 'printer'][0],

			/* FIRST PAGE */
			/* usual values: 'landing', 'instructions', 'form1' */
			firstPageOfThisForm: 'form1',



			/* Is it a P&G form? */
			/* If true, allow OneTrust tag and remove GDPR, Matomo, CookieFirst… */
			/* If false… Don't forget to update the IDs and CODES on mixins "ga" and "cf" for google analytics and cookie first */
			/* true value remove the GDPR */
			isPnGAction: true,

			/* GDPR */
			/* It is enabled by default on non-p&g action, but could be removed.
			For example: in case of integration in an iframe. */
			isGdprPresent: true,
			/* For the "CLIENT" name, see the translation files */



			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,

			/* BREADCRUMBS */
			displayBreadCrumbs: true,
			listOfCrumbsForThisAction: ['store', 'form', 'cashback'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			
			/* DEFAULT LOCALE */
			/* Useful to force nl_NL instead of nl_BE */
			/* ! See in the i18n.js file */

			/* This is an action with a coupon in EVERY participation (ex: "action coupon" or coupon as gift) */
			itsAnActionCoupon: false,
			/* If not, we may allow a coupon in the mail (if survey is filled) */
			aCouponCouldBeSent: false,
			/* Is there a survey at all ?*/
			thereIsASurveyInThisAction: false,

			/* Is there a visible counter? */
			counterIsVisible: false,

			/* REQUIRED FIELDS to UNLOCK BUTTONS */
			/* List of fields that must be valid for a specific button to unlock */
			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['firstName', 'lastName', 'email', 'IBAN'], /* 'firstName', 'lastName', , 'IBAN', 'street1', 'houseNumber', 'postalCode', 'city', 'countries' */
				'btn_cashback_on_form2' : ['file', 'conditions', 'optin2'],
				'btn_cashback_on_survey': [], /* 'idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3' */
				'btn_cashback_on_printcoupon': ['conditionsCoupons'],
				/* INFO: it is possible to use an array to tell "if one of theses is filled, it is ok". Example below: */
				'example_with_array_of_possibles_value': [ 'firstName', ['idTitle', 'genre'], 'lastName'], // if idTitle OR genre is filled, the array is considered true.
			},
			/* REQUIRED FIELDS to UNLOCK THE FORM */
			/* Ultime check to see if something is missing despite the buttons check */
			/* Must never include optionnel answers like survey's */
			allRequiredFields: [ 'firstName', 'lastName', 'email', 'IBAN', 'file', 'conditions', 'optin2' /* 'firstName', 'lastName', 'IBAN', 'street1', 'houseNumber', 'postalCode', 'city', 'countries', 'file' */ ],

			/* ! "countries" must not be required when the google API put the value in the field, as it is not validated by action api then. */
			/* TODO: find a way to make action api validate that field */

			/* ALL default fieldname that can be checked
					'idTitle',
					'gender',
					'firstName',
					'lastName',
					'email',
					'phoneNumber1',
					'IBAN', 
					'VATNumber',
					'street1',
					'houseNumber',
					'boxNumber',
					'postalCode',
					'city',
					'countries',
					'selectProduct',
					'purchaseDate',
					'idPOPRetailer',
					'freeRetailerName',
					'idLeadTMD1', 'idLeadTMD2',…
					'serialNumber',
					'file',
					'conditions',
					'uniqueCode'

			*/

			

		}
	},
}
