export default {
    props: {
        fieldname: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: false,
        },
        autocompleteName: {
            type: String,
            required: false,
        },
        placeholder : {
            type: String,
            required : false,
        },
        helper : {
            type: String,
            required: false
        },
        translationSource : {
            type: String,
            required: false,
        },
        isRequired : {
            type: Boolean,
            required: true,
        },
        modelValue: {
            // Support all types
            // subselection of types => type: [String, Number, Boolean, Array, Object],
            // type: String,
            required: true
        },
        label : {
            type: String,
            required : false,
        },
    },
    data() {
        return {
            isInError: false,
            isInSuccess : false,
            isLoading: false,
            displayedInTheField: null,
        }
    },
    computed: {
      labelSpaced(){
        /* convert space before some punctuation to unbreakable ones */
        return this.label.replace(/ ([?!:;]?)/, "&nbsp;$1");
    },

    lengthOfInput(){
            // If input is a number of a text, return it's length
        return String(this.displayedInTheField).length;
    },
    customLabel(){
        let label = this.$t(`inputsTexts.${this.translationSource}.label`);
        return this.label ? this.label : label;
    },
    customPlaceholder(){
        let placeholder = this.$t(`inputsTexts.${this.translationSource}.placeholder`);
        return this.placeholder ? this.placeholder : placeholder;
    },
    customHelper(){
        let helper = this.$t(`inputsTexts.${this.translationSource}.helper`);
        return this.helper ? this.helper : helper;
    },

    idLead: function(){
        return this.$store.getters.getLeadId;
    },
},
methods: {
    giveError() {
        this.isInError = true;
        this.isInSuccess = false;
    },
    giveSuccess() {
        this.isInError = false;
        this.isInSuccess = true;
    },
    removeSuccessOrError() {
        this.isInError = false;
        this.isInSuccess = false;
    },
    setLoading() {
        this.isLoading = true;
    },
    setNotLoading() {
        this.isLoading = false;
    },
}
}